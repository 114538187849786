import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  fetchOrdersAsync,
  selectOrderList,
} from "../../../redux/slices/orderSlice";
import { Link } from "react-router-dom";
import OrderDetail from "../../../component/orderDetail/OrderDetail";
import { useAppDispatch } from "../../../redux/hooks";
import { IGetOrderList, OrderStatusText } from "../../../interfaces/order";
import { selectUser } from "../../../redux/slices/userSlice";

const Orders = () => {
  const orderList = useSelector(selectOrderList);
  const user = useSelector(selectUser);
  const [selectedOrder, setSelectedOrder] = React.useState<any>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user?.user) {
      return;
    }

    dispatch(fetchOrdersAsync({} as IGetOrderList));
  }, []);
  return (
    <>
      {!selectedOrder && (
        <div className="dashboard__orders card">
          <div className="card-header">
            <h5>Siparişlerim</h5>
          </div>
          <div className="card-divider"></div>
          <div className="card-table">
            <div className="table-responsive-sm">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Sipariş No</th>
                    <th scope="col">Tarih</th>
                    <th scope="col">Durum</th>
                    <th scope="col">Toplam Tutar</th>
                  </tr>
                </thead>
                <tbody>
                  {orderList && orderList.length > 0 ? (
                    orderList.map((order) => (
                      <tr 
                      onClick={() => setSelectedOrder(order)}>
                        <td>{order.id}</td>
                        <td>
                          {new Date(order.createdAt).toLocaleDateString()}
                        </td>
                        <td>{order.status}</td>
                        <td>
                          {order.orderItems
                            .map((o) => o.product.price * o.quantity)
                            .reduce((a, b) => a + b, 0)
                            .toFixed(2) + " TL"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4}>Henüz siparişiniz bulunmamaktadır.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <div className="mt-3">
        {selectedOrder && (
          <>
            <button
              className="btn btn-outline-secondary mb-2"
              onClick={() => setSelectedOrder(null)}
            >
              {"< Geri"}
            </button>
            <OrderDetail order={selectedOrder} />
          </>
        )}
      </div>
    </>
  );
};

export default Orders;
