import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice";
import { Link } from "react-router-dom";
import { selectDefaultAddress } from "../../../redux/slices/addressSlice";
import { fetchOrdersAsync, selectOrderList } from "../../../redux/slices/orderSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { IGetOrderList } from "../../../interfaces/order";

export default function Dashboard() {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const defaultAddress = useSelector(selectDefaultAddress);
  const orderList = useSelector(selectOrderList);
  const userName = user?.user?.firstName + " " + user?.user?.lastName;
  const email = user?.user?.email;

  useEffect(() => {
    if (!user?.user) {
      return
    }

    dispatch(fetchOrdersAsync({} as IGetOrderList));
  }, []);


  return (
    <div className="dashboard">
      <div className="dashboard__profile card profile-card">
        <div className="card-body profile-card__body">
          <div className="profile-card__avatar">
            <img alt="" src="/assets/images/blank-profile-picture.webp" />
          </div>
          <div className="profile-card__name">{userName}</div>
          <div className="profile-card__email">{email}</div>
          <div className="profile-card__edit">
            <Link
              className="btn btn-secondary btn-sm"
              to="/account/edit-profile"
            >
              Profili Düzenle
            </Link>
          </div>
        </div>
      </div>
      <div className="card address-card dashboard__address address-card--featured">
        <div className="card__loader"></div>
        {!!defaultAddress && defaultAddress.default ? (
          <>
            <div className="address-card__badge tag-badge tag-badge--theme">
              Varsayılan
            </div>
            <div className="address-card__body">
              <div className="address-card__name">{defaultAddress.name}</div>
              <div className="address-card__row">
                {defaultAddress.cityName} / {defaultAddress.districtName} / {defaultAddress.neighborhoodName}
              </div>
              <div className="address-card__row">
                <div className="address-card__row-title">Telefon Numarası</div>
                <div className="address-card__row-content">
                  {defaultAddress.phone}
                </div>
              </div>
              <div className="address-card__row">
                <div className="address-card__row-title">Email Address</div>
                <div className="address-card__row-content">
                  {defaultAddress.email}
                </div>
              </div>
              <div className="address-card__footer">
                <Link to="/account/addresses">Adresleri Düzenle</Link>
              </div>
            </div>
          </>
        ) : (
          <div className="address-card__body">
            <div className="address-card__row">
              <div className="address-card__row-title">Varsayılan Adres</div>
              <div className="address-card__row-content">
                Henüz varsayılan adres eklenmedi
              </div>
            </div>
            <div className="address-card__footer">
              <Link to="/account/addresses">Adres Ekle</Link>
            </div>
          </div>
        )}
      </div>
      <div className="dashboard__orders card">
        <div className="card-header">
          <h5>Son Siparişlerim</h5>
        </div>
        <div className="card-divider"></div>
        <div className="card-table">
          <div className="table-responsive-sm">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Sipariş No</th>
                  <th scope="col">Tarih</th>
                  <th scope="col">Durum</th>
                  <th scope="col">Toplam Tutar</th>
                </tr>
              </thead>
              <tbody>
                {orderList && orderList.length > 0 ? (
                  orderList.map((order) => (
                    <tr>
                      <td>
                        <Link to={`profile/orders/${order.id}`}>
                          {order.id}
                        </Link>
                      </td>
                      <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                      <td>{order.status}</td>
                      <td>{order.orderItems.map(o => (o.product.price * o.quantity)).reduce((a,b) => a+b, 0).toFixed(2) + ' TL'}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>Henüz siparişiniz bulunmamaktadır.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
